import * as navigationService from './navigation-service';
/**
 * 公共模块-包含信息和用户
 */
/**
 * @param {Object} url
 */
function moduleLoad(url) {
	$.get(url, function(result) {
		var html = $(result);
		// console.log(1);
		var __templates = html;
		$("[slot]").each(function() {
			var id = $(this).attr('slot');
			var body = $(__templates).find('#' + id).html();
			$(this).html(body);
			dealModule($(this));
		});
		getTreeNums()
		dealtree();
		var loginUser = sessionStorage.wavplayLoginAdminUser;
		if(loginUser == undefined || loginUser == "undefined" || loginUser == null || loginUser == ""){
			getUserInfo();
		}else{
			var loginOV = JSON.parse(loginUser);
			let nowtime = new Date().getTime();
			let expirationTime = loginOV.expirationTime;
			if(expirationTime<nowtime){
				getUserInfo();
			}
			
			$("#uname").html(loginOV.realname);
			$("#portrait").attr("src",loginOV.portrait);
			// $("#portrait").attr("src",rooturl+"/"+loginOV.portrait);
		}
		getUnreadnum();
	});
}

function dealModule(templates) {
	try {
		//初始化页面元素
		templates.find('[data-i18n-placeholder]').each(function() {
			$(this).attr('placeholder', $.i18n.prop($(this).data('i18n-placeholder')));
		});
		templates.find('[data-i18n-text]').each(function() {
			//若是text里面还有html须要过滤掉
			var html = $(this).html();
			var reg = /<(.*)>/;
			if (reg.test(html)) {
				var htmlValue = reg.exec(html)[0];
				$(this).html(htmlValue + $.i18n.prop($(this).data('i18n-text')));
			} else {
				$(this).text($.i18n.prop($(this).data('i18n-text')));
			}
		});
		templates.find('[data-i18n-value]').each(function() {
			$(this).val($.i18n.prop($(this).data('i18n-value')));
		});
		templates.find('[data-i18n-html]').each(function() {
			$(this).html($.i18n.prop($(this).data('i18n-html')));
		});
		templates.find('[data-i18n-after-html]').each(function() {
			var html = $(this).html();
			$(this).html($.i18n.prop($(this).data('i18n-after-html')) + html);
		});
	} catch (ex) {}
}

// $(document).ready(function() {
// 	moduleLoad("./template/PublicModule.html");
// });

/**
 * 登出
 */
$(document).on("click", "#logoutBtn", () => {
	//销毁token
	localStorage.clear();
	sessionStorage.clear();
	//跳转登录
	navigationService.goToPage("login.html");
});

//导航显示隐藏
$(document).on("click", ".sidebarCollapse", function(e) {
	if ($('#sidebar').hasClass("active")) {
		$(".out-side-toggle").addClass("d-md-none");
		$(".inside-menu-toggle").removeClass("d-none");
		$('#sidebar').removeClass("active")
	} else {
		$(".out-side-toggle").removeClass("d-md-none");
		$(".inside-menu-toggle").addClass("d-none");
		$('#sidebar').addClass("active")
	}
});

$(document).on("click", ".components li", function() {
	sessionStorage.leftTree = $(this).data("no");
});
$(document).on("click", ".adminaccount li", function() {
	sessionStorage.accountTree = $(this).data("no");
});
